<template>
  <div class="page-coupon">
    <headeBar left-arrow :color="header_color" :title="header_title" :background="header_background" :opacity="header_opacity" :placeholder="false" @click-left="newAppBack" />
    <div v-if="hasData">
      <img :src="item" v-for="item in headList" />
      <img :src="buttonUrl" @click="receive" />
      <img :src="item" v-for="item in bottomList" />
    </div>
    <div v-else class="nodata">
      <img alt class="noimg" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/empty.png" />
      <div class="notext">无可领取优惠券</div>
    </div>
    <van-popup v-model="showGet" position="center" class="pop-get" :close-on-click-overlay="false">
      <div class="pop-get-head"></div>
      <div class="pop-get-body">
        <div class="content-box">
          <div class="coupon-list">
            <div class="coupon-model" :class="{ 'coupon-disabled': !item.acceptable }" v-for="item in couponList">
              <div class="model-left">
                <span class="value">{{ item.discount }}</span
                >元
              </div>
              <div class="shu"></div>
              <div class="model-right">
                <div class="name ellipsis">{{ item.name }}</div>
                <div class="del">{{ item.spend ? '满元' + item.spend + '可用' : '无门槛' }}</div>
              </div>
              <div class="model-rule ellipsis" v-if="!item.acceptable && item.unacceptableReason" @click="$toast(item.unacceptableReason)">{{ item.unacceptableReason }}</div>
            </div>
            <!-- <div class="coupon-model coupon-disabled">
                            <div class="model-left"><span class="value">9.9</span>元</div>
                            <div class="shu"></div>
                            <div class="model-right">
                                <div class="name ellipsis">1对1专业指导私教sdasd是课</div>
                                <del class="del">课程价值299元</del>
                            </div>
                            <div class="model-rule">限小班课新人可领</div>
                        </div> -->
          </div>
          <div class="pop-get-sub" @click="goCoursePage">前往约课</div>
          <div class="pop-get-tips">恭喜您成功领取，优惠券已发放至您的账户</div>
        </div>
      </div>
      <img class="popclose" src="https://img.chaolu.com.cn/ACT/coupon-202312/close.png" @click="showGet = false" />
    </van-popup>
    <van-popup v-model="showHasGet" position="center" class="pop-hasget" :close-on-click-overlay="false">
      <div class="ps-body">
        <div class="ps-msg">
          <b>领取失败</b>
          <div class="errmsg">您暂无可领取优惠券</div>
        </div>
        <div class="pop-get-sub" @click="goCoursePage">前往约课</div>
      </div>
      <img class="popclose" src="https://img.chaolu.com.cn/ACT/coupon-202312/close.png" @click="showHasGet = false" />
    </van-popup>
  </div>
</template>
<script>
import { newAppBack, initBack, gotoAppPage } from '@/lib/appMethod'
import headeBar from '@/components/app/headBar'
import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'
import Vconsole from 'vconsole'
import wx from 'weixin-js-sdk'
export default {
  mixins: [userMixin, headerMixin],
  components: {
    headeBar,
  },
  data() {
    return {
      showGet: false,
      hasData: true,
      showHasGet: false,
      headList: [],
      buttonUrl: '',
      bottomList: [],
      status: undefined,
      couponList: [],
      errorMsg: '',

      isTencent: false, // 是否是朋友圈广告
      tencentParam: {}, // 特殊处理 朋友圈广告
      id_: ''
    }
  },
  mounted() {
    this.bindHeaderScroll(window, 200)
  },
  async created() {
    initBack()
    if (location.href.indexOf('483729') > -1) new Vconsole()

    // 判断是否来自朋友圈 start
    const fakeId = this.$route.params.id
    console.log(fakeId);

    const hr = window.location.href
    this.id = fakeId.indexOf('&') > -1 ? fakeId.split('&')[0] : fakeId
    this.isTencent = hr.indexOf('gdt_vid') > -1 || hr.indexOf('clickId') > -1

    if (this.isTencent) {
      var urlParam = hr.split('?')[1]
      var paramArr = urlParam.split("&")

      for (var i = 0; i < paramArr.length; i++) {
          var item = paramArr[i].split('=')
          this.tencentParam[item[0]] = item[1]
      }
      console.log(this.tencentParam);

    } else {
      await this.$getAllInfo()
    }
    // 判断是否来自朋友圈 end

    this.userId_ = this.isTencent ? this.tencentParam.userId : this.userId
    this.token_ = this.isTencent ? this.tencentParam.token : this.token
    this.id_ = this.id
    this.getVoucher()

    // this.addUserAction('VIEW_CONTENT')
  },
  methods: {
    newAppBack,
    goCoursePage() {
      gotoAppPage(18)
    },
    getVoucher() {
      this.$axios
        .post(this.baseURLApp + '/activityStrategyConfig/getActivityInfo', {
          userId: this.userId_,
          token: this.token_,
          id: this.id_,
        })
        .then((res) => {
          const d = res.data
          this.status = d.activityStatus == 'NOT_STARTED' ? 'not-in' : d.activityStatus == 'STARTED' ? 'in' : 'end'
          if (this.status == 'in') {
            this.headList = d.headBgImg.split(',')
            this.buttonUrl = d.buttonImg
            this.bottomList = d.tailBgImg.split(',')
            this.hasData = this.buttonUrl ? true : false
            document.title = d.title
          } else {
            this.$toast('未在活动时间内')
            this.hasData = false
          }
        })
        .catch(() => {
          this.hasData = false
        })
    },
    receive() {
      if (this.status != 'in') {
        this.$toast('未在活动时间内')
        return
      }
      this.$toast.loading()
      this.$axios
        .post(this.baseURLApp + '/activityStrategyConfig/acceptAwardByConfig', {
          userId: this.userId_,
          token: this.token_,
          id: this.id_,
        })
        .then((res) => {
          if (res.data.status === 0) {
            this.$toast.clear()
            this.couponList = res.data.userRecords
            this.showGet = true
            this.addUserAction('CLAIM_OFFER')
          } else {
            this.errorMsg = res.data.unReceiveMsg
            this.showHasGet = true
            // setTimeout(()=>{
            //     this.$toast(this.errorMsg);
            // },1000)
          }
        })
        .catch(() => {
          console.log(123)
        })
    },
    /**
     * 小程序广告转化
     * @param actionType 预定义的行为类型，目前只支持COMPLETE_ORDER（下单）及RESERVATION（表单预约）、CONFIRM_EFFECTIVE_LEADS（有效销售线索）
     */
    addUserAction(actionType) {
      const clickId = this.isTencent ? (this.tencentParam.clickId || this.tencentParam.gdt_vid) : (this.$route.query.clickId || this.$route.query.gdt_vid)
      if (!clickId) {
        return false
      }
      this.$axios
        .post(
          `${this.baseURLApp}/user/behavior/tx/data-nexus/ad-track-data/add`,
          {
            clickId,
            actionType: actionType,
            userId: this.userId_
          },
          false
        )
        .then((res) => {
          console.log('广告数据转化埋点：类型', actionType, '返回参数', res)
        })
        .catch((r) => {
          console.log('广告数据转化埋点：类型', actionType, '埋点接口调用失败')
        })
    },
  },
}
</script>
<style lang="less" scoped>
.page-coupon {
  background: #f5f5f5;
  height: 100vh;

  div {
    box-sizing: border-box;
  }

  img {
    vertical-align: top;
    width: 100%;
  }

  .nodata {
    margin: 0 auto;
    width: 320px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .noimg {
      width: 320px;
      height: 320px;
    }

    .notext {
      font-size: 26px;
      color: #6c727a;
      margin-top: 20px;
    }
  }

  .pop-get {
    background: transparent;

    .pop-get-head {
      background: url(https://img.chaolu.com.cn/ACT/coupon-202312/pophead.png);
      background-size: 100% 100%;
      height: 143px;
      margin-bottom: -1px;
    }

    .pop-get-body {
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      width: 566px;
      background: linear-gradient(to right, #fffaed, #ffe6b3);
      text-align: center;
      padding: 0 12px 12px;

      .content-box {
        border-radius: 36px;
        background: #fff;
        padding: 24px;
        padding-bottom: 20px;
      }

      .coupon-list {
        min-height: 300px;
        max-height: 370px;
        overflow: auto;

        .coupon-model {
          display: flex;
          align-items: center;
          background: #fdfaf1;
          border-radius: 16px;
          height: 150px;
          margin-bottom: 24px;
          position: relative;

          &:last-of-type {
            margin-bottom: 0;
          }

          .model-left {
            width: 170px;
            color: #a84812;
            font-weight: bold;
            font-size: 24px;
            text-align: center;

            .value {
              font-size: 48px;
            }
          }

          .shu {
            width: 2px;
            height: 80px;
            border-right: 2px dashed #f6c19c;
          }

          .model-right {
            flex: 1 0 0;
            overflow: hidden;
            padding: 0 30px;
            text-align: left;

            .name {
              font-size: 28px;
              color: #242831;
              font-weight: bold;
              line-height: 1;
            }

            .del {
              margin-top: 10px;
              font-size: 22px;
              color: #6c727a;
            }
          }

          .model-rule {
            position: absolute;
            right: 0;
            bottom: 0;
            min-width: 100px;
            max-width: 200px;
            height: 36px;
            line-height: 36px;
            background: #a0a0a0;
            border-radius: 16px 0px 16px 0px;
            color: #fff;
            padding: 0 20px;
            font-size: 20px;
          }

          &.coupon-disabled {
            background: #eee;

            .model-left {
              color: #b1b1b1;
            }

            .shu {
              border-color: #c9c9c9;
            }

            .model-right {
              .name,
              .del {
                color: #b1b1b1;
              }
            }
          }
        }
      }

      .pop-get-sub {
        height: 98px;
        line-height: 96px;
        background: linear-gradient(90deg, #ffcf4f 0%, #ffb21c 100%);
        border-radius: 12px;
        margin: 32px auto 28px;
        text-align: center;
        color: #242831;
        font-weight: bold;
      }

      .pop-get-tips {
        color: #3c454e;
        font-size: 22px;
        line-height: 1;
      }
    }

    .popclose {
      width: 60px;
      height: 60px;
      display: block;
      margin: 64px auto 0;
    }
  }

  .pop-hasget {
    background: transparent;

    .ps-body {
      width: 574px;
      height: 558px;
      background: url(https://img.chaolu.com.cn/ACT/coupon-202312/popbg2.png);
      background-size: 100% 100%;
      padding-top: 150px;

      .ps-msg {
        height: 140px;
        padding: 15px 52px 0;
        font-size: 36px;
        color: #242831;
        text-align: center;
        line-height: 60px;
        overflow: hidden;

        .errmsg {
          font-size: 26px;
          line-height: 20px;
          margin-top: 18px;
        }
      }

      .pop-get-sub {
        width: 420px;
        height: 98px;
        line-height: 96px;
        background: linear-gradient(90deg, #ffcf4f 0%, #ffb21c 100%);
        border-radius: 12px;
        margin: 100px auto 28px;
        text-align: center;
        color: #242831;
        font-weight: bold;
      }
    }

    .popclose {
      width: 60px;
      height: 60px;
      display: block;
      margin: 64px auto 0;
    }
  }
}
</style>
